import * as React from 'react';
import { useRef, useEffect, createContext, useContext, useState } from 'react';
import { useSmoothScroll } from '../scrolling/SmoothScroll';
import useScreenSize, { Size } from './UseScreenSize';
import Observable, { useObservable } from './Observable';
import styled from 'styled-components';

// common data structures

interface SectionDef {
  div: React.MutableRefObject<HTMLDivElement>;
  id: string;
}

export interface ViewportState {
  center: number;
  height: number;
}

export interface SectionState {
  visible: boolean;
  ratio: number;
  side: number;
  viewport: ViewportState;
  rect: { top: number; bottom: number; height: number; center: number };
}

const defaultViewportState: ViewportState = {
  center: 0,
  height: 0
};

const defaultSectionState: SectionState = {
  visible: false,
  ratio: 0,
  side: 1,
  viewport: defaultViewportState,
  rect: { top: 0, bottom: 0, height: 0, center: 0 }
};

// area

class SectionAreaState {
  private states = new Map<string, Observable<SectionState>>();
  private sections = new Map<string, SectionDef>();

  private viewport: ViewportState = { center: 0, height: 0 };

  addSection(section: SectionDef) {
    if (this.sections.has(section.id)) return;

    this.sections.set(section.id, section);
    this.updateSectionState(section);
  }

  removeSection(section: SectionDef) {
    this.sections.delete(section.id);
  }

  getSectionState(sectionId: string): Observable<SectionState> {
    if (!this.states.has(sectionId)) {
      const section = this.sections.get(sectionId);
      const state =
        section !== undefined
          ? this.updateSectionState(section)
          : defaultSectionState;
      this.states.set(sectionId, new Observable<SectionState>(state));
    }

    return this.states.get(sectionId);
  }

  updateSectionState(section: SectionDef): SectionState {
    const div = section.div.current;
    const rect = div.getBoundingClientRect();

    const rectCenter = rect.top + rect.height / 2;
    const viewportCenter = this.viewport.height / 2;

    const state: SectionState = {
      visible: false,
      ratio: 0,
      side:
        viewportCenter < rectCenter ? 1 : rectCenter < viewportCenter ? -1 : 0,
      viewport: { center: viewportCenter, height: this.viewport.height },
      rect: {
        top: rect.top,
        bottom: rect.bottom,
        center: rectCenter,
        height: rect.height
      }
    };

    const topVisible = 0 <= rect.top && rect.top < this.viewport.height;

    state.visible = topVisible || (rect.top < 0 && 0 < rect.bottom);

    if (state.visible) {
      const bottomVisible = rect.bottom < this.viewport.height;
      if (topVisible) {
        if (bottomVisible) {
          state.ratio = 1;
        } else {
          state.ratio = 1 - (rect.bottom - this.viewport.height) / rect.height;
        }
      } else if (bottomVisible) {
        state.ratio = (rect.height + rect.top) / rect.height;
      } else {
        state.ratio =
          (rect.height + rect.top - (rect.bottom - this.viewport.height)) /
          rect.height;
      }
    }

    if (!this.states.has(section.id)) {
      this.states.set(section.id, new Observable<SectionState>(state));
    } else {
      this.states.get(section.id).set(state);
    }

    return state;
  }

  updateState(scroll: number, screenSize: Size) {
    this.viewport = {
      center: screenSize.height / 2,
      height: screenSize.height
    };

    this.sections.forEach(section => {
      this.updateSectionState(section);
    });
  }
}

const SectionContext = createContext(new SectionAreaState());

const SectionArea = <T extends string>({
  children
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const context = useContext(SectionContext);
  const scroll = useSmoothScroll();
  const screenSize = useScreenSize();

  useEffect(() => {
    context.updateState(scroll, screenSize);
  }, [context, scroll, screenSize]);

  return <>{children}</>;
};

// section

const Section = <TId extends string>({
  id,
  children = null,
  height = 0,
  color = null
}: {
  id: TId;
  children?: JSX.Element | JSX.Element[];
  height?: number;
  color?: string
}) => {
  const div = useRef(null as HTMLDivElement);
  const context = useContext(SectionContext);
  const [def] = useState({ id: id, div: div });
  const style = {
    ...(color ? { border: `10px solid ${color}` }: {}),
    ...(height !== 0 ? { height: height }: { height: 'auto'})
  };

    useEffect(() => {
    context.addSection(def);
    return () => context.removeSection(def);
  }, [context, def]);

  return (
    <div ref={div} style={style}>
      {children}
    </div>
  );
};

// useSection

const useSection = <T extends string>(section: T) => {
  const context = useContext(SectionContext);
  const [state, setState] = useState(context.getSectionState(section).value);

  const onSectionChanged = (state: SectionState) => {
    setState(state);
  };

  useObservable(context.getSectionState(section), onSectionChanged);

  return state;
};

// SectionContent

const SectionContent = ({
  id,
  children,
  setState
}: {
  id: string;
  children: JSX.Element | JSX.Element[];
  setState: (state: SectionState) => void;
}) => {
  const section = useSection(id);

  useEffect(() => {
    if (section.visible === false) return null;
    setState(section);
  }, []);

  return <>{children}</>;
};

export { useSection, Section, SectionArea, SectionContent };
