import * as React from 'react';
import {about} from '../../../data/About';
import styled from 'styled-components';
import Accentuate from '../../common/Accentuate';
import {
  useSection,
  SectionState,
} from '../../../helpers/common/Section';
import {useState, useEffect} from 'react';
import useScreenSize from '../../../helpers/common/UseScreenSize';
import interpolate from '../../../helpers/tween/Interpolate';
import {animated} from 'react-spring';
import {useDevSpring} from '../../../helpers/common/Springs';
import Tools from './tools';


const useSectionInterpolation = <T extends object>(
  id: string,
  target: number,
  fullRange: number,
  fadeRange: number,
  fullStart: T,
  fullEnd: T,
  fadeStart: T,
  fadeEnd: T): [SectionState, number, T] => {

  const section = useSection(id);
  if (!section.visible)
    return [section, 0, fadeEnd];

  const ratio = (section.viewport.center - section.rect.top) / section.rect.height;

  const minFull = target - fullRange;
  const maxFull = target + fullRange;
  const minFaded = minFull - fadeRange;
  const maxFaded = maxFull + fadeRange;

  let result;

  if (ratio <= minFaded) {
    result = {...fadeStart};
  } else if (ratio <= minFull) {
    const t = (ratio - minFaded) / (minFull - minFaded);
    result = interpolate(fadeStart, fullStart, t);
  } else if (ratio <= maxFull) {
    const t = (ratio - minFull) / (maxFull - minFull);
    result = interpolate(fullStart, fullEnd, t);
  } else if (ratio <= maxFaded) {
    const t = (ratio - maxFull) / (maxFaded - maxFull);
    result = interpolate(fullEnd, fadeEnd, t);
  } else {
    result = {...fadeEnd};
  }

  return [section, ratio, result];
};

const AboutElement = ({target, setRatio = null, children}:
                        {
                          target: number,
                          setRatio?: (value: number) => void,
                          children: JSX.Element | JSX.Element[]
                        }) => {
  const size = useScreenSize();
  const [section, ratio, current] = useSectionInterpolation('about', target, .1, .1,
    {
      opacity: 1,
      y: size.height * .55
    },
    {
      opacity: 1,
      y: size.height * .45
    },
    {
      opacity: 0,
      y: size.height
    },
    {
      opacity: 0,
      y: 0
    });

  const style = useDevSpring({
    opacity: current.opacity,
    transform: `translateY(${current.y}px)`
  });

  useEffect(() => {
    if (setRatio) {
      setRatio(ratio);
    }
  }, [ratio]);

  return section.visible
    ? <animated.div style={style}>
      {children}
    </animated.div> : null;
};

const Paragraph = styled(animated.div)`
  width: 40rem;
  height: fit-content;
  color: black;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: 'Raleway', sans-serif;
`;

const Left = styled(Paragraph)`
  margin-left: 10%;
`;

const TopLeft = styled(Left)`
  margin-top: 20%;
`;

const Right = styled(Paragraph)`
  margin-right: 10%;
  right: 0;
  text-align: right;
`;

const BottomRight = styled(Right)`
  margin-bottom: 20%;
  bottom: 0;
`;

const AboutDiv = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
`;

const Row = styled.div`
  width: auto;
`;

const Break = styled.div`
  height: 2rem;
`;

const About = () => {

  const section = useSection('about');

  const f = Math.max(0, 1 - section.ratio);

  const useStyle = () => useDevSpring({
    transform: `translateY(${500 * (1 - section.ratio)}px)`,
    opacity: 1 - f
  });

  const topStyle = useStyle();
  // if(!section.visible) return null;

  return (
    <AboutDiv>
      <Row>
        <Paragraph >
          <Accentuate text={about.intro} accentuate={['Hadrien']}/>
          <Break/>
          <Accentuate
            text={about.experience}
            accentuate={['user interfaces', 'games', 'production tools']}
          />
          <Break/>
          {/* <Accentuate
            text={about.tools}
            accentuate={['Unity', '.net', 'C#', 'React', 'Typescript']}
          />
          <Break/> 
          <Tools ratio={1}/>
          <Break/>*/}
          <Accentuate
            text={about.speciality}
            accentuate={['scales', 'user experiences']}
          />
          <Break/>
          <Accentuate
            text={about.service}
            accentuate={['help you']}
          />
          <Break/>
          <Accentuate
            text={about.getInTouch}
            accentuate={['']}
          />
          <Break/>
        </Paragraph>
      </Row>
    </AboutDiv>
  );
};

export default About;
