import React from 'react';
import World from './World';
import Sections from './Sections';
import GlobalStyle from './common/GlobalStyle';
import About from './content/about';
import styled from 'styled-components';
import { DevReloadClock } from '../helpers/common/DevReloadClock';

const Content = styled.div`
  height: 100%;
  width: 100%;
  z-index: 20;
  position: fixed;
  top: 0;
  display: block;
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <DevReloadClock>
        <Sections />
        {/* <Content>
          <About />
        </Content>*/}
        <World />
      </DevReloadClock>
    </>
  );
}

export default App;
