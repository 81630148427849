import { useEffect } from 'react';

class Hashset<T> extends Array<T> {
  constructor() {
    super();
    Object.setPrototypeOf(this, Object.create(Hashset.prototype));
  }

  public add(item: T): boolean {
    const index = this.indexOf(item);
    if (index > 0) return false;
    this.push(item);
    return true;
  }

  public remove(item: T): boolean {
    const index = this.indexOf(item);
    if (index < 0) return false;
    this.splice(index, 1);
    return true;
  }
}

export const useHashSetElement = <T extends unknown>(
  hashset: Hashset<T>,
  element: T
) => {
  useEffect(() => {
    hashset.add(element);
    return () => hashset.remove(element);
  }, [element, hashset]);
};

export default Hashset;
