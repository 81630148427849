import * as React from 'react';
import {createContext, useReducer} from 'react';
import {useWaitFrames} from './Hooks';
import devMode from './DevMode';

const DevReloadClockContext = createContext({devClock: {started: false}, dispatch: (_: boolean) => {}});

const DevReloadClock = (
  {children = null}: {children?: JSX.Element | JSX.Element[]}) => {
  
  const initialState = { started: !devMode };
  const setStarted = (state: { started: boolean }, value: boolean) => {
    return {...state, started: value}
  };
  
  const [devClock, dispatch] = useReducer(setStarted, initialState);
  useWaitFrames(2, () => dispatch(true));
  
  return (
    <DevReloadClockContext.Provider value={{devClock, dispatch}}>
      {children}
    </DevReloadClockContext.Provider>);
};

export { DevReloadClockContext, DevReloadClock }