import * as React from 'react';
import { Section } from '../../helpers/common/Section';

export type SectionId = 'header' | 'work' | 'about';

const PortfolioSection = ({
  id,
  children = null,
  height = 0
}: {
  id: SectionId;
  children?: JSX.Element | JSX.Element[];
  height?: number;
}) => (
  <Section id={id} height={height}>
    {children}
  </Section>
);

export default PortfolioSection;
