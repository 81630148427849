import { createContext } from 'react';
import StateSerializer from '../../helpers/common/StateSerializer';
import devMode from '../../helpers/common/DevMode';

const devSettings = {
  skipIntro: true,
  smoothScroll: true,
  autoScroll: false
};

const state = devMode
  ? devSettings
  : {
      skipIntro: false,
      smoothScroll: true,
      autoScroll: false
    };

const AppSettings = createContext(state);
if (devMode) {
  StateSerializer.saveState('app', state);
}

export { devMode, AppSettings };
