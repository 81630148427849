import interpolate from "./Interpolate";

export interface TweenProps<T> {
  start: T;
  end: T;
  duration: number;
  setter: (x: T) => void;
  easing?: (t: number) => number;
  delay?: number;
  id?: [number, (value: number) => void];
  onComplete?: () => void;
}

const tween = <T extends unknown>({
  start,
  end,
  duration,
  setter,
  easing = null,
  id = null,
  onComplete = null,
  delay = 0
}: TweenProps<T>) => {
  //const delta = {};
  //const keys = Object.keys(start);

  const [tweenId, setTweenId] = id ? id : [null, null];

  /*for (let key of keys) {
		delta[key] = end[key] - start[key];
	}*/

  if (tweenId) {
    cancelAnimationFrame(tweenId);
  }

  const startTween = () => {
    const startTime = Date.now();
    const durationMs = duration * 1000;
    const endTime = startTime + durationMs;

    if (duration === 0) {
      setter(interpolate(start, end, 1));
      return;
    }

    const step = (t: number) => {

      const now = Date.now();

      if (now >= endTime) {
        if (setTweenId) setTweenId(null);
        setter(interpolate(start, end, 1));
        if (onComplete) {
          onComplete();
        }
      } else {
        const t = (now - startTime) / durationMs;
        setter(interpolate(start, end, t, easing));
        const handle = requestAnimationFrame(step);
        if (setTweenId) setTweenId(handle);
      }
    };

    const handle = requestAnimationFrame(step);
    if (setTweenId) setTweenId(handle);
  };

  if (delay) {
    const handle = setTimeout(startTween, delay * 1000);
    if (setTweenId) setTweenId(handle);
  } else {
    startTween();
  }
};

const cancelTween = (handle: number) => {
  cancelAnimationFrame(handle);
};

export { tween, cancelTween };
