const info = {
  firstName: 'Hadrien',
  lastName: 'Grandry',
  job: 'Freelance Developer',
  tag: 'Drawing squares since 1998.',
  speciality: 'Unity .net',
  location: 'Montreal & remote'
};

const about = {
  intro: "Hi there! My name is Hadrien, I'm a freelance developer living in Montreal.",
  experience: "I have been developing software for over 15 years, crafting user interfaces, games and production tools.",
  tools: "I love exploring new tools and technologies. These days I'm working mostly with Unity and C# for games or interactive content, .net for the server-side and tools, and React with Typescript for web development.",
  speciality: "Over the years, I have developed a process to write modular software that scales and delivers high-end user experiences.",
  service: 'I can help you kick-off your project the right way, build and improve your existing code-base and provides analysis and counseling to grow your project.',
  getInTouch: 'Let\'s get in touch!'
};

export { info, about };
