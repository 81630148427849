import Hashset from "./Hashset";
import { useEffect } from "react";

type Listener<T> = (value: T) => void;

class Observable<T>{
  value: T;
  listeners = new Hashset<Listener<T>>();

  constructor(value: T) {
    this.value = value;
  }

  set(value: T) {
    this.value = value;
    for (let listener of this.listeners) {
      listener(value)
    }
  }

  subscribe(listener: Listener<T>) {
    this.listeners.add(listener);
    listener(this.value);
  }

  unsubscribe(listener: Listener<T>) {
    this.listeners.remove(listener);
  }
}

const useObservable = <T extends unknown>(observable: Observable<T>, listener: Listener<T>) => {

  useEffect(() => {
    observable.subscribe(listener);
    return () => observable.unsubscribe(listener);

  }, [observable, listener])
}

export { useObservable };

export default Observable;