import * as React from 'react';
import useScreenSize, { Size } from '../../../helpers/common/UseScreenSize';
import { CSSProperties } from 'styled-components';
import styled from 'styled-components';
import { Centered } from '../../../helpers/common/Layout';
import { info } from '../../../data/About';
import { animated as a, useSpring } from 'react-spring';
import PortfolioSection from '../../common/PortfolioSection';
import { AppSettings } from '../../common/AppSettings';
import { useContext } from 'react';

const Div = styled.div`
  display: block;
  position: relative;
  color: white;
  overflow: hidden;
`;

const Name = styled(a.div)`
  font-size: 3.8rem;
  transform: translateY(1.5rem);
`;

const Infos = styled.div`
  font-size: 2.1rem;
  transform: translate(7rem, 3rem);
`;

const Job = styled(a.div)``;

const Location = styled(a.div)`
  color: white;
  font-size: 1.5rem;
`;

const divStyle = (props: { size: Size }): CSSProperties => ({
  height: props.size.height - 1,
  width: props.size.width - 20
});

const Header = () => {
  const screenSize = useScreenSize();
  const settings = useContext(AppSettings);

  const immediate = settings.skipIntro;

  const name = useSpring({
    opacity: 1,
    transform: 'translateX(-9.1rem)',
    from: {
      opacity: 0,
      transform: 'translateX(-20rem)'
    },
    delay: immediate ? 0 : 500,
    immediate
  });

  const job = useSpring({
    opacity: 1,
    transform: 'translateX(3rem)',
    from: {
      opacity: 0,
      transform: 'translateX(10rem)'
    },
    delay: immediate ? 0 : 1000,
    immediate
  });

  const loc = useSpring({
    opacity: 1,
    transform: 'translate(3rem, .5rem)',
    from: {
      opacity: 0,
      transform: 'translate(10rem, .5rem)'
    },
    delay: immediate ? 0 : 1200,
    immediate
  });

  return (
    <PortfolioSection id='header'>
      <Div style={divStyle({ size: screenSize })}>
        <Centered>
          <Name style={name}>
            {info.firstName} {info.lastName}
          </Name>
          <Infos>
            <Job style={job}>{info.job}</Job>
            <Location style={loc}>{info.location}</Location>
          </Infos>
        </Centered>
      </Div>
    </PortfolioSection>
  );
};

export default Header;
