import { useEffect, useState } from 'react';
import scrollTo from './ScrollTo';

interface Props {
  scrollHandler: (y: number) => void;
  scrollTarget?: {
    addEventListener;
    removeEventListener;
    scrollY: number;
  };
}

const useScroll = (scrollTarget = null) => {
  const [scroll, setScroll] = useState((scrollTarget || window).scrollY);
  let frameId = 0;

  const handleScroll = () => {
    const target = scrollTarget || window;
    frameId = 0;

    setScroll(target.scrollY);
  };

  const onScroll = () => {
    if (!frameId) {
      frameId = requestAnimationFrame(handleScroll);
    }
  };

  const addEvents = () => {
    const target = scrollTarget || window;
    if (target) {
      target.addEventListener('scroll', onScroll);
    }
  };

  const removeEvents = () => {
    const target = scrollTarget || window;

    if (target) {
      target.removeEventListener('scroll', onScroll);
    }

    if (frameId) {
      cancelAnimationFrame(frameId);
    }
  };

  // init, dispose
  useEffect(() => {
    addEvents();
    handleScroll();
    return () => {
      removeEvents();
    };
  }, []);

  // scrollTarget changed
  useEffect(() => {
    removeEvents();
    addEvents();
  }, [scrollTarget]);

  return scroll;
};

export default useScroll;
