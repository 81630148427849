import { useState, useEffect } from 'react';

export interface Size {
  width: number;
  height: number;
}

export const getScreenSize = (): Size => {
  return {
    width: Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    ),
    height: Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
  };
};

const useScreenSize = () => {
  const [size, setSize] = useState(() => getScreenSize());

  const onResize = () => {
    setSize(getScreenSize());
  };

  useEffect(() => {
    window.addEventListener('resize', onResize, true);

    return () => {
      window.removeEventListener('resize', onResize, true);
    };
  }, []);

  return size;
};

export default useScreenSize;
