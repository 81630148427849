import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import contactData /*, { mailLink, linkedin } */ from '../../../data/Contact';
// import { Paragraph } from '../../common/Layout';

const ContactSection = styled.footer`
  padding: 0 1.5rem 1.5rem;
`;
/*
const Text = styled(Paragraph)<{ colors: any }>`
  display: block;
  margin: 25px auto 0 auto;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: ${({ colors }) => 'black'};
  & a {
    color: ${({ colors }) => 'black'};
    text-decoration: none;
  }
`;*/

const ContactList = styled.ul`
  height: 4rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Contact = styled.a`
  display: inline-block;
  cursor: pointer;
  margin: 0.3rem;
`;

const Contacts = () => {
  return (
    <ContactSection>
      {/* <SectionTitle colors={colors}>Contact</SectionTitle> */}
      {/* <Text colors={colors}>Hello blabla bla</Text> */}
      {/* <Text colors={{ titleColor: 'black' }}>
        If you have questions, or want to talk about your project...
        <br />
        You can reach me at <a href={mailLink}>hadrien.grandry@gmail.com</a> or
        via <a href={linkedin}>Linkedin</a>.
        <br />
        <br />
        Looking forward to talking with you!
      </Text> */}
      <ContactList>
        {contactData.map((contact, index) => {
          const Icon = contact.icon;
          const iconStyle: CSSProperties = {
            color: '#328ABE',
            width: `${contact.width}rem`,
            height: `${contact.height}rem`,
            position: 'relative',
            top: `${contact.top}rem`
          };

          return (
            <Contact key={index} href={contact.link}>
              {/* <TintImage
                width={24}
                height={24}
                src={process.env.PUBLIC_URL + '/' + contact.image}
                color={colors.workTitle as Color}
              /> */}
              <Icon style={iconStyle} />
            </Contact>
          );
        })}
      </ContactList>
    </ContactSection>
  );
};

export default Contacts;
