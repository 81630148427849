import React, { useEffect } from 'react';
import { Canvas, useThree } from 'react-three-fiber';
import { Color as ThreeColor, Uncharted2ToneMapping } from 'three';
import HeaderScene from './content/header/scene';
import { useContext } from 'react';
import { GlobalStyleContext } from './common/GlobalStyle';
import * as THREE from 'three';

const ViewportTracker = () => {
  const { viewport, scene } = useThree();
  const context = useContext(GlobalStyleContext);

  useEffect(() => {
    if (viewport === undefined) return;

    // setup for three inspector
    window['scene'] = scene;
    window.THREE = THREE;

    context.viewport = viewport;
  });

  return null;
};

const Root = ({ children }) => {
  return (
    <Canvas
      style={{ position: 'fixed', top: 0, zIndex: -1 }}
      onCreated={({ gl }) => {
        gl.toneMapping = Uncharted2ToneMapping;
        gl.setClearColor(new ThreeColor('white'));
      }}>
      <ambientLight />
      {children}
    </Canvas>
  );
};

const World = () => (
  <Root>
    <ViewportTracker />
    <HeaderScene />
  </Root>
);

export default World;
