import { useState, useEffect, Dispatch } from 'react';

class StateSerializer {
  static loadState(id: string) {
    try {
      const serializedState = localStorage.getItem(id);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }

  static saveState(id: string, state: unknown) {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(id, serializedState);
    } catch (err) {
      // Ignore write errors.
    }
  }
}

const useSerializedState = <T extends unknown>(
  id: string,
  defaultState: T,
  serializingCondition?: () => boolean
): [T, (value: T) => void] => {
  const initialState = (StateSerializer.loadState(id) as T) ?? defaultState;
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (!serializingCondition || serializingCondition() === true) {
      StateSerializer.saveState(id, state);
    }
  }, [state]);

  return [state, setState];
};

export { useSerializedState };

export default StateSerializer;
