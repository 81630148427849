import React, { useState, useEffect, useRef, useContext } from 'react';
import { useThree, useUpdate, useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import { useSmoothScroll } from '../../../helpers/scrolling/SmoothScroll';
import useScreenSize from '../../../helpers/common/UseScreenSize';
import { Vector3, Mesh, PlaneGeometry, BufferGeometry, Geometry } from 'three';
import { a, useSpring } from 'react-spring/three';
import Easing from '../../../helpers/tween/Easing';
import { Spring } from 'react-spring/renderprops-universal';
import { interpolate } from 'react-spring';
import { useSection } from '../../../helpers/common/Section';
import { SectionId } from '../../common/PortfolioSection';
import { AppSettings } from '../../common/AppSettings';

const Plane = props => {
  const geometry = useRef(null as Geometry);
  /*const [defaultVertices, setDefaultVertices] = useState(null as Vector3[]);
  
  const modulate = (
    value: number,
    factor: number,
    min: number,
    max: number
  ) => {
    let delta = factor * Math.random();
    if (value < min) return value + delta;
    if (value > max) return value - delta;
    if (Math.random() > 0.5) return value + delta;
    return value - delta;
  };
 
  useEffect(() => {
    if (defaultVertices) return;
    const geo = geometry.current;
    if (!geo) return;
    setDefaultVertices(geo.vertices.slice(0));
  }, [geometry]);

  useFrame(() => {
    const geo = geometry.current;
    if (!geo) return;
    for (let i = 0; i < geo.vertices.length; i++) {
      const v = geo.vertices[i];
      const vDefault = defaultVertices[i];
      const factor = 0.01;
      const range = 0.01;
      const min = -range;
      const max = range;
      v.x = modulate(v.x, factor, vDefault.x + min, vDefault.x + max);
      v.y = modulate(v.y, factor, vDefault.y + min, vDefault.y + max);
      geo.vertices[i].set(v.x, v.y, v.z);
      geo.verticesNeedUpdate = true;
    }
  });*/

  return (
    <a.mesh
      name={`plane${props.index}`}
      position={props.position}
      rotation={props.rotation}
      scale={props.scale}>
      <a.meshBasicMaterial
        attach='material'
        transparent={true}
        opacity={1}
        color={props.color}
      />
      <a.planeGeometry
        ref={geometry}
        attach='geometry'
        args={[props.width ?? 1, props.height ?? 1]}
      />
    </a.mesh>
  );
};

const Circle = props => {
  return (
    <a.mesh
      name={`circle${props.index !== undefined ? props.index : ''}`}
      position={props.position}
      rotation={props.rotation}
      scale={props.scale}>
      <a.meshBasicMaterial
        attach='material'
        transparent={true}
        opacity={1}
        color={props.color}
      />
      <a.circleGeometry
        attach='geometry'
        args={[props.radius ?? 1, props.segments ?? 32]}
      />
    </a.mesh>
  );
};

const config = {
  intro: {
    tension: 170
  },
  out: {
    tension: 100
  }
};

const HeaderScene = () => {
  const { camera } = useThree();
  const scroll = useSmoothScroll();
  const screenSize = useScreenSize();
  const [groupScale, setGroupScale] = useState(1);
  const section = useSection<SectionId>('header');
  const settings = useContext(AppSettings);

  const yOut = -2.5;
  const startOut = 0.15;
  const active = section.ratio > startOut;
  const ratioOut = active || !section.visible ? 1 : startOut - section.ratio;
  const beforeOutRatio = section.ratio;

  const useAnimatedShape = ({ focus, from, out = null }) => {
    out = out ? { ...focus, ...out } : focus;
    return useSpring({
      ...(active ? focus : focus),
      from: from,
      config: active ? config.intro : config.out,
      immediate: settings.skipIntro
    });
  };

  const plane1 = useAnimatedShape({
    from: {
      position: [3, -15, 0],
      rotation: [0, 0, 5],
      opacity: 0
    },
    focus: {
      color: '#2979B0',
      scale: Array(3).fill(2.4),
      position: [0, 0, -1],
      rotation: [0, 0, 0],
      opacity: 1
    },
    out: {
      scale: Array(3).fill(1),
      position: [-5 * ratioOut, 0, -1],
      rotation: [0, 0, -5 * ratioOut]
    }
  });

  const plane2 = useAnimatedShape({
    from: {
      position: [-5, -15, 0],
      rotation: [0, 0, 3],
      opacity: 0
    },
    focus: {
      color: '#15A7D9',
      scale: Array(3).fill(3),
      position: [0.2, 0, -2],
      rotation: [0, 0, -0.2],
      opacity: 1
    },
    out: {
      scale: Array(3).fill(2),
      position: [-5, 0, -2],
      rotation: [0, 0, -3.2 * ratioOut]
    }
  });

  const plane3 = useAnimatedShape({
    from: {
      position: [20, -15, -5],
      rotation: [0, 0, 0],
      opacity: 0
    },
    focus: {
      color: '#62a4ca',
      scale: Array(3).fill(3 + 3 * beforeOutRatio),
      position: [3.1, 0, -3],
      rotation: [0, 0, 5.32],
      opacity: 1
    },
    out: {
      scale: Array(3).fill(3),
      position: [12, -15, -5],
      rotation: [0, 0, 3.3 * ratioOut]
    }
  });

  const circle = useAnimatedShape({
    from: {
      position: [20, -15, -10],
      rotation: [0, 0, 0],
      opacity: 0
    },
    focus: {
      color: '#62a4ca',
      radius: 5,
      segments: 64,
      scale: Array(3).fill(1 + 3 * beforeOutRatio),
      position: [3.1, 0, -5],
      rotation: [0, 0, 5.32],
      opacity: 1
    },
    out: {
      scale: Array(3).fill(3),
      position: [12, -15, -5],
      rotation: [0, 0, 3.3 * ratioOut]
    }
  });

  const planes = [plane1, plane2, plane3];

  useEffect(() => {
    /*const newScale =
      screenSize.width > 900 ? 1 : screenSize.width > 600 ? 0.75 : 0.5;
    setGroupScale(newScale);*/
  }, [screenSize]);

  const setCamera = cameraPos => {
    camera.position.x = cameraPos.x;
    camera.position.y = cameraPos.y;
    camera.position.z = cameraPos.z;
    return null;
  };

  const scale = groupScale;
  const cameraZ = 5;
  const cameraZStart = settings.skipIntro ? cameraZ : -10;
  //cameraZ = cameraZ * 1 + (cameraZ * (1 - section.visibleRange) * .2);

  return (
    <group scale={[scale, scale, scale]}>
      <Spring
        from={{ x: 0, y: 0, z: cameraZStart }}
        to={{ x: 0, y: 0, z: cameraZ }}>
        {props => setCamera(props)}
      </Spring>
      <group position={[-2.5, scroll / 100, 0.1]}>
        <pointLight position={[10, 10, 10]} />
        {planes.map((el, i) => (
          <Plane key={i} {...el} index={i + 1} />
        ))}
        {/* <Circle {...circle} /> */}
      </group>
    </group>
  );
};

export default HeaderScene;
