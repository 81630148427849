import { useSpring } from 'react-spring';
import { useSpring as useSpringThree} from 'react-spring/three';
import { useContext } from 'react';
import {DevReloadClockContext} from './DevReloadClock';
import devMode from './DevMode';

const useDevSpring = !devMode? useSpring: (params: any) => {
  const {devClock} = useContext(DevReloadClockContext);
  const options = devClock.started 
    ? {...params, immediate: params.immediate, delay: params.delay }
    : {...params, immediate: true, delay: 0 };
  return useSpring(options);
};

const useDevSpringThree = !devMode? useSpringThree: (params: any) => {
  const {devClock} = useContext(DevReloadClockContext);
  const options = devClock.started
    ? {...params, immediate: params.immediate, delay: params.delay }
    : {...params, immediate: true, delay: 0 };
  return useSpringThree(options);
};

export { useDevSpring, useDevSpringThree }