/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import styled from 'styled-components';
import { ScrollMagnetArea } from '../helpers/scrolling/ScrollMagnet';
import Header from './content/header';
import DummySection from './content/dummy';
import Contacts from './content/contact';
import { SmoothScrollArea } from '../helpers/scrolling/SmoothScroll';
import { SectionArea, Section as SectionImpl } from '../helpers/common/Section';
import { AppSettings, devMode } from './common/AppSettings';
import { useContext } from 'react';
import { useSerializedState } from '../helpers/common/StateSerializer';
import { useTimeoutFn } from 'react-use';
import scrollTo from '../helpers/scrolling/ScrollTo';
import Easing from '../helpers/tween/Easing';
import useScreenSize from '../helpers/common/UseScreenSize';
import About from './content/about';
import Color from 'color';

const Div = styled.div`
  z-index: 10;
`;

export type SectionId = 'header' | 'about' | 'intro' | 'work';

const Section = ({
  id,
  children = null,
  color = null,
  height = 0
}: {
  id: SectionId;
  children?: JSX.Element | JSX.Element[];
  color?: string,
  height?: number;
}) => (
  <SectionImpl id={id} height={height} color={color}>
    {children}
  </SectionImpl>
);

const Sections = () => {
  const settings = useContext(AppSettings);
  const screenSize = useScreenSize();
  const [scroll, setScroll] = useSerializedState('scroll', 0, () => devMode);

  /*useTimeoutFn(() => {
    if (settings.skipIntro || !settings.autoScroll) return;
    scrollTo({ target: 1000, duration: 0.8, easing: Easing.easeInOutCubic });
  }, 1500);*/

  return (
    <SmoothScrollArea
      enabled={settings.smoothScroll}
      startValue={scroll}
      onChange={setScroll}>
      <SectionArea>
        <Div>
          <ScrollMagnetArea debug={false} mode='default'>
            <Header />
            {/* <About /> */}
            <Section id='about' color={null} >
              <About />
            </Section>
            {/*<DummySection name='Work' color='green' sectionId='work' />*/}
            <Contacts />
          </ScrollMagnetArea>
        </Div>
      </SectionArea>
    </SmoothScrollArea>
  );
};

export default Sections;
