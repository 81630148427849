

const interpolate = <T extends unknown>(
  start: T,
  end: T,
  t: number,
  easing?: (t: number) => number,
) => {
  if (easing)
    t = easing(t);

  if (typeof start === 'number' && end !== undefined) {
    const value = start + (end as number - start as number) * t;
    return value as T;
  } else if (Array.isArray(start) && end !== undefined) {
    const array = [];
    for (let i = 0; i < start.length; i++) {
      array.push(interpolate(start[i], end[i], t));
    }
    return array as T;
  } else if (typeof start === 'object' && end !== undefined) {
    const keys = Object.keys(start);
    const obj = {};
    for (const key of keys) {
      obj[key] = interpolate(start[key], end[key], t);
    }
    return obj as T;
  } else {
    return start;
  }
};

export default interpolate;