import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import React, { createContext } from 'react';
import useScreenSize from '../../helpers/common/UseScreenSize';
import { usePrevious } from '../../helpers/common/Hooks';
import { useContext } from 'react';

const GlobalStyleContext = createContext({
  viewport: { width: 0, height: 0, factor: 100 }
});

const GlobalStyleImpl = createGlobalStyle<{ fontSize: number }>`
  ${reset}

  body {
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: ${({ fontSize }) => `${fontSize}%`};
  }
`;

const GlobalStyle = () => {
  const screenSize = useScreenSize();
  const previousSize = usePrevious(100);
  const context = useContext(GlobalStyleContext);

  let newSize = 100;
  newSize = Math.min(100, screenSize.height / 10.3);

  return <GlobalStyleImpl fontSize={newSize} />;
};

export { GlobalStyleContext };
export default GlobalStyle;
