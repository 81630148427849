import * as React from 'react';
import styled from 'styled-components';
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import useScroll from './UseScroll';
import Hashset from '../common/Hashset';
import { animated, useSpring } from 'react-spring';
import { useInit, useUpdate, usePrevious } from '../common/Hooks';
import {DevReloadClockContext} from '../common/DevReloadClock';

// context: internal
class SmoothScrollState {
  value = 0;
  listeners = new Hashset<(value) => void>();
  preRender = true;

  setValue(x: number) {
    this.value = x;
    for (const listener of this.listeners) {
      listener(x);
    }
  }
}

const SmoothScrollContext = createContext(new SmoothScrollState());

const HiddenDiv = styled.div`
  visibility: hidden;
  z-index: -1;
`;

const ContentDiv = styled(animated.div)`
  position: fixed;
`;

// area

const SmoothScrollArea = ({
  children = null,
  tension = 150,
  enabled = true,
  startValue = 0,
  onChange = null
}: {
  children?: JSX.Element | JSX.Element[];
  tension?: number;
  enabled?: boolean;
  startValue?: number;
  onChange?: (y: number) => void;
}) => {
  const contentDiv = useRef(null as HTMLDivElement);
  const context = useContext(SmoothScrollContext);
  let scroll = useScroll(null);
  const previousScroll = usePrevious(startValue);
  const [divHeight, setDivHeight] = useState(500);
  const {devClock} = useContext(DevReloadClockContext);   // hack  to restore previous scrolling when restarting
  const started = devClock.started;
  
  scroll =  started ? scroll : startValue;
  // console.log("hello");

  const smoothScroll = useSpring({
    top: -scroll,
    from: { top: -(previousScroll ?? startValue) },
    config: { tension: tension },
    onFrame: (value: { top: number }) => {
      if(!started){
        window.scrollTo(0, startValue);
      }
      context.setValue(-value.top);
    },
    immediate: !enabled || !started
  });
  
  useInit(() => {
    context.setValue(startValue);
  });
  
  useUpdate(() => {
    if (contentDiv.current == undefined) return;
    const newDivHeight = contentDiv.current.getBoundingClientRect().height;
    setDivHeight(newDivHeight);
  });

  useEffect(() => {
    if (!started) return;
    if (onChange) onChange(scroll);
  }, [scroll]);
  
  return (!enabled ? <>{children}</>:
    <>
      <HiddenDiv
        style={{
          height: `${divHeight}px`
        }}
      />
      <ContentDiv ref={contentDiv} style={smoothScroll}>
        {children}
      </ContentDiv>
    </>
  );
};

// use

const useSmoothScroll = () => {
  const context = useContext(SmoothScrollContext);
  const [smoothScroll, setSmoothScroll] = useState(0);

  useEffect(() => {
    context.listeners.add(setSmoothScroll);
    setSmoothScroll(context.value);
    return () => context.listeners.remove(setSmoothScroll);
  }, []);

  return smoothScroll;
};

export { useSmoothScroll, SmoothScrollArea, SmoothScrollContext };
