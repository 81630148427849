import * as React from 'react';
import { createContext, useContext, useRef, useState, useEffect } from 'react';
import useScroll from './UseScroll';
import scrollTo from './ScrollTo';
import { Size } from '../common/UseScreenSize';
import Hashset from '../common/Hashset';
import Easing from '../tween/Easing';

// context

type ScrollMode = 'default' | 'closest' | 'closestForward' | 'nextForward';

interface ScrollMagnetContextProps {
  mode?: ScrollMode;
  easing?: (x: number) => number;
  tweenDuration?: number;
  children: unknown;
  debug?: boolean;
}

class SrollMagnetContextState {
  public props: ScrollMagnetContextProps;
  public closestName = '';
  public onCurrentChange?: (name: string) => void;

  private scrollY = 0;
  private screenSize: Size = { width: 0, height: 0 };
  private current?: ScrollMagnetState;
  private magnets = new Hashset<ScrollMagnetState>();
  private inTransition = false;
  private scrollStep = 0;

  public init(props: ScrollMagnetContextProps) {
    this.props = props;
  }

  public setScroll(y: number) {
    this.scrollStep = y - this.scrollY;
    this.scrollY = y;
    this.update();
  }

  public setScreenSize(size: Size) {
    this.screenSize = size;
  }

  public add(magnet: ScrollMagnetState) {
    if (this.magnets.add(magnet)) {
      this.update();
    }
  }

  public remove(magnet: ScrollMagnetState) {
    if (this.magnets.remove(magnet)) {
      this.update();
    }
  }

  private update() {
    if (this.props.mode === 'default') return;

    if (this.inTransition === true) return;

    let deltaY = 0;
    let closest: ScrollMagnetState = null;
    let closestDist = Number.MAX_VALUE;
    let closestDelta = 0;

    if (this.props.mode === 'nextForward') {
      const dir = this.scrollStep > 0 ? 1 : -1;
      const currentIndex = this.current
        ? this.magnets.indexOf(this.current)
        : 0;
      const nextIndex = Math.max(
        0,
        Math.min(currentIndex + dir, this.magnets.length - 1)
      );
      if (nextIndex === currentIndex) return;
      const magnet = this.magnets[nextIndex];

      const el = magnet.getElement();
      const rect = el.getBoundingClientRect();
      deltaY = rect.top - this.screenSize.height * magnet.y;
      closest = magnet;
      closestDelta = deltaY;
    } else {
      this.magnets.forEach(magnet => {
        if (magnet === this.current) return;

        const el = magnet.getElement();
        const rect = el.getBoundingClientRect();
        deltaY = rect.top - this.screenSize.height * magnet.y;

        // magnet is active only if we scroll toward it
        if (this.props.mode !== 'closest' && this.scrollStep * deltaY < 0)
          return;

        const dist = Math.abs(deltaY);

        if (
          magnet.minDistance <= dist &&
          dist <= magnet.maxDistance &&
          dist < closestDist
        ) {
          closest = magnet;
          closestDist = dist;
          closestDelta = deltaY;
          this.closestName = closest.name;
          if (this.onCurrentChange != null) this.onCurrentChange(closest.name);
        }
      });
      if (!closest || closest === this.current) return;
    }

    this.current = closest;

    this.inTransition = true;
    scrollTo({
      target: this.scrollY + closestDelta,
      duration: this.props.tweenDuration ?? 1,
      easing: this.props.easing ?? Easing.easeOutQuint,
      onComplete: () => (this.inTransition = false)
    });
  }
}

const ScrollMagnetContext = createContext(new SrollMagnetContextState());

// area

const ScrollMagnetArea = ({
  children,
  mode = 'closestForward',
  easing = Easing.easeOutCubic,
  tweenDuration = 0.5,
  debug = false
}: ScrollMagnetContextProps) => {
  const context = useContext(ScrollMagnetContext);
  // const screenSize = useScreenSize(size => context.setScreenSize(size));
  const [currentName, setCurrentName] = useState(context.closestName);
  context.onCurrentChange = name => setCurrentName(name);
  context.init({ mode, easing, tweenDuration, children });
  context.setScroll(useScroll());
  return (
    <>
      <div>
        {debug ? (
          <div style={{ position: 'fixed', color: 'white' }}>{currentName}</div>
        ) : null}
        {children}
      </div>
    </>
  );
};

// magnet

interface ScrollMagnetProps {
  name?: string;
  minDistance?: number;
  maxDistance?: number;
  children?: JSX.Element;
  x?: number;
  y?: number;
}

interface ScrollMagnetState {
  name?: string;
  getElement(): HTMLDivElement;
  active: boolean;
  minDistance: number;
  maxDistance: number;
  x: number;
  y: number;
}

const ScrollMagnet = ({
  name,
  children,
  minDistance = 10,
  maxDistance = 300,
  x = 0.5,
  y = 0.5
}: ScrollMagnetProps) => {
  const context = useContext(ScrollMagnetContext);
  const div = useRef(null as HTMLDivElement);
  const [magnet] = useState({
    getElement: () => div.current,
    name: name,
    minDistance: minDistance,
    maxDistance: maxDistance,
    x: x,
    y: y
  } as ScrollMagnetState);

  useEffect(() => {
    context.add(magnet);
    return () => context.remove(magnet);
  }, [context, magnet]);

  return (
    <div ref={div} style={false ? { border: 'thick solid red' } : {}}>
      {children}
    </div>
  );
};

export { ScrollMagnet, ScrollMagnetArea };
