import styled from 'styled-components';
import React, {useContext, useMemo} from 'react';
import {SmoothScrollContext} from '../../helpers/scrolling/SmoothScroll';

const Accent = styled.span`
  color: #15a7d9;
  font-weight: bold;
`;

const Accentuate = ({
  text,
  accentuate
}: {
  text: string;
  accentuate: string[];
}) => {
  return useMemo(() => {
    if (accentuate.length == 0) return <>{text}</>;

    //console.log('render accentuate');
    const blocks = text.split(accentuate[0]);

    return (
      <>
        {blocks.map((block, index) => {
          return (
            <span key={index}>
              <Accentuate text={block} accentuate={accentuate.slice(1)}/>
              {index < blocks.length - 1 ? (
                <Accent>{accentuate[0]}</Accent>
              ) : null}
            </span>
          );
        })}
      </>
    );
  }, [text, ...accentuate]);
};

export default Accentuate;
