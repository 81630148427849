import {useEffect, useRef, useState} from "react";

const useInit = (init: () => void) => {
  useEffect(init, []);
};

const useUpdate = (update: () => void) => {
  const frame = useRef(0);

  const step = () => {
    update();
    frame.current = requestAnimationFrame(step);
  };

  useEffect(() => {
    frame.current = requestAnimationFrame(step);
    return () => cancelAnimationFrame(frame.current);
  }, []);
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useWaitFrames = (count: number, then: () => void) => {
  const counter = useRef(0);
  const endCount = count;
  const frame = useRef(0);

  const increment = () => {
    counter.current += 1;
    if(counter.current < endCount){
      frame.current = requestAnimationFrame(increment);
    }else{
      then();
    }
  };

  useEffect(() => {
    frame.current = requestAnimationFrame(increment);
    return () => cancelAnimationFrame(frame.current);
  }, []);
};

export { useInit, useUpdate, usePrevious, useWaitFrames };
