import {TwitterSquare} from '@styled-icons/fa-brands/TwitterSquare';
import {Linkedin} from '@styled-icons/fa-brands/Linkedin';
import {GithubSquare} from '@styled-icons/fa-brands/GithubSquare';
import {Mail} from '@styled-icons/foundation/Mail';
import {StyledIcon} from 'styled-icons/types';
import {FilePdf} from '@styled-icons/fa-solid/FilePdf';

export const links = {
  resume: 'https://drive.google.com/file/d/1ICIL-f8s4XvXaCnHamv-GuiVcvcJEUO-/view?usp=sharing',
  mail: 'mailto:contact@hgrandry.net',
  linkedin: 'https://www.linkedin.com/in/hgrandry',
  github: 'https://github.com/hgrandry'
  //twitter: 'https://twitter.com/hgrandry'
};

export interface ContactDescription {
  icon: StyledIcon;
  link: string;
  width: number;
  height: number;
  top: number;
}

const size = {
  width: 2,
  height: 2,
  top: 0
};

const contacts: ContactDescription[] = [
  {
    ...size,
    height: 1.9,
    icon: FilePdf,
    link: links.resume
  }, {
    ...size,
    width: 2.5,
    height: 2.5,
    icon: Mail,
    link: links.mail
  }, {
    ...size,
    icon: Linkedin,
    link: links.linkedin
  }, {
    ...size,
    icon: GithubSquare,
    link: links.github
  }
  /*, {
    ...size,
    icon: TwitterSquare,
    link: links.twitter
  }*/
];

export default contacts;
