import { tween } from '../tween';

interface ScrollToProps {
  target: HTMLDivElement | number;
  duration: number | ((dist: number) => number);
  easing: (t: number) => number;
  offsetTop?: number;
  onComplete?: () => void;
}

const scrollTo = ({ target, duration, easing, offsetTop = 0, onComplete = null }) => {
  let y = typeof target === 'number' ? target : target.offsetTop;
  if (offsetTop) y += offsetTop;
  y = Math.max(0, y);

  const scrollMax = document.documentElement.scrollHeight - window.innerHeight;
  if (y > scrollMax) y = scrollMax;

  let d = 0;
  if (typeof duration === 'number') {
    d = duration;
  } else {
    const dist = Math.abs(window.scrollY - y);
    d = duration(dist);
  }

  tween({
    start: window.scrollY,
    end: y,
    duration: d,
    setter: y => window.scrollTo(0, y),
    easing: easing,
    onComplete: onComplete,
  });
};

export default scrollTo;
